import axios from 'axios'

import { ApiService } from '@/common/api_services'
import { i18n } from '@/common/i18n'
import { API_BASE } from '@/conf'
export const OrderService = {
    vendorOrdersList(pk, params) {
        return ApiService.query(`vendor-orders/${pk}/`, { params: params })
    },
    query(params) {
        return ApiService.query('dashboard-order-list/', { params: params })
    },
    get(pk) {
        return ApiService.get('dashboard-order-detail', pk)
    },
    create(params) {
        return ApiService.post('dashboard-order-create/', params)
    },
    print(id) {
        return ApiService.query(`dashboard-order/${id}/print/`)
    },
    getInstallments(params) {
        return ApiService.query('dashboard-order-installments/', {
            params: params,
        })
    },
    sendEmail(params) {
        return ApiService.post(`send-email/${params.id}/`)
    },
    rescheduleInstallment(params) {
        return axios.post(
            API_BASE +
                `${i18n.locale}/hyp-payment/escalated/${params.order_number}/${params.installment_number}/`,
          {},
          {withCredentials: true}
        )
        // return ApiService.post(
        //     `hyp-payment/escalated/${params.order_number}/${params.installment_number}/`
        // )
    },
    deleteOrder(id) {
        return ApiService.delete(`dashboard-order-delete/${id}/`)
    },
}
