export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_ERROR = 'USER_ERROR'
export const USER = {
    LIST: 'getUserList',
    GET: 'getUser',
    CREATE: 'createUser',
    EDIT: 'editUser',
    DELETE: 'deleteUser',
    SET_OBJECT: 'setUser',
    SET_LIST: 'setUserList',
}
