export const USERS = {
    GET_AUTH_USER: 'getAuthUser',
    SET_AUTH_USER: 'setAuthUser',
    LIST: 'getUsersList',
    VENDOR_CUSTOMERS_LIST: 'getVendorCustomersList',
    GET_USER: 'getUser',
    UPDATE_USER: 'updateUser',
    UPDATE_AUTH_USER: 'updateAuthUser',
    CREATE: 'createUser',
    SET_LIST: 'setUsersList',
    SET_USER: 'setUser',
    SHOW_ROW: 'showCustomer',
    SET_VISIBILITY_ROW: 'setVisibilityCustomer',
    SEARCH: 'search',
    LIST_VENDORS: 'getListVendors',
    SET_LIST_VENDORS: 'setListVendors',
    USER_AUTH_LOGOUT: 'userAuthLogOut',
    SET_COMPANIES_LIST: 'setCompaniesList',
    COMPANIES_LIST: 'getCompaniesList',
}

export const USER = {
    GENERATE_OTP: 'setGenerateOtp',
    VERIFY_OTP: 'setVerifyOtp',
    RESET_PASSWORD: 'setNewPassword',
}
