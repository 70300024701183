<template>
    <div>
        <TheHeader v-if="isAuthenticated" :userDetails="userAuth" />
        <form style="display: none">
            <button></button>
        </form>
        <main role="main" v-if="isAuthenticated">
            <div class="dashboard">
                <div class="container-fluid">
                    <div class="flex-wrapper">
                        <div class="dashboard__left">
                            <TheMenu :userDetails="userAuth" />
                        </div>
                        <div class="dashboard__right">
                            <router-view />
                            <div class="copy-right">
                                © {{ new Date().getFullYear() }} BuyTech
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <div v-else>
            <router-view />
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import cookieHandler from '@/common/cookies-service'
import { UsersHelper } from '@/common/crud-helpers/users'
import TheHeader from '@/common/layout/TheHeader.vue'
import TheMenu from '@/common/layout/TheMenu.vue'
import { VUE_APP_WS_NOTIFICATIONS } from '@/conf'
import { AUTH_LOGOUT, REFRESH_TOKEN } from '@/modules/auth/store/actions'

export default {
    name: 'App',
    components: {
        TheHeader,
        TheMenu,
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'token', 'userAuth', 'refreshToken']),
    },
    watch: {
        token() {
            if (this.token.length > 5) this.createSocketConnection()
        },
    },
    mounted() {},
    created() {
        //======================================================
        // let refreshing
        // navigator.serviceWorker.addEventListener(
        //     'controllerchange',
        //     function () {
        //         if (refreshing) return
        //         window.location.reload()
        //         refreshing = true
        //     }
        // )
        // Set up the headers for axios requests
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = 'X-CSRFToken'
        axios.interceptors.request.use(
            function (config) {
                console.log("ON FULLFILLED")
                let token = cookieHandler.getCookie('user-token')
                if (token != null)
                    config.headers.Authorization = `Bearer ${token}`
                config.headers['Accept-Language'] =
                    cookieHandler.getCookie('locale') || 'en'
                let csrftoken = cookieHandler.getCookie('csrftoken')
                console.log(csrftoken)
                if (csrftoken != null) config.headers['X-CSRFToken'] = csrftoken
                return config
            },
            function (err) {
                return Promise.reject(err)
            }
        )

        axios.interceptors.response.use(
            (response) => {
                console.log(response)
                return response
            },
            (error) => {
                const { config, response } = error
                const originalRequest = config
                if (
                    (response.status === 403 || response.status === 401) &&
                    !originalRequest._retry
                ) {
                    originalRequest._retry = true
                    this.$store.dispatch(REFRESH_TOKEN)
                    if (!cookieHandler.getCookie('user-refresh-token')) {
                        this.$store.dispatch(AUTH_LOGOUT)
                        this.$router.push({
                            name: 'login',
                            params: {
                                lang: this.$i18n.locale,
                            },
                        })
                    }
                }
                return Promise.reject(error)
            }
        )
        // If authenticated fetch the logged-in user info
        if (this.$store.getters.isAuthenticated) {
            UsersHelper.getAuthUser()
        }
        // If lang is Arabic add attr/class to the global html tag
        if (this.$i18n.locale === 'ar') {
            document.getElementsByTagName('html')[0].lang = 'ar'
            document.getElementsByTagName('html')[0].classList.add('ar')
        }
        if (this.token.length > 5) this.createSocketConnection()
    },
    methods: {
        urlB64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
            const base64 = (base64String + padding)
                .replace(/-/g, '+')
                .replace(/_/g, '/')

            const rawData = window.atob(base64)
            const outputArray = new Uint8Array(rawData.length)
            return outputArray.map((output, index) => rawData.charCodeAt(index))
        },
        ...mapMutations([
            'SocketConnected',
            'SocketDisconnected',
            'SocketError',
            'WebsocketMessageReceived',
        ]),
        createSocketConnection() {
            Vue.prototype.$socket = new WebSocket(
                `${VUE_APP_WS_NOTIFICATIONS}?token=${this.token}`
            )
            this.$socket.onopen = (event) => {
                console.log('Connection established', event)
                this.SocketConnected()
                // setInterval(() => {
                //     this.$socket.send('Hello from front end')
                // }, 20000)
                // this.$socket.send('Hello from front end!')
            }
            this.$socket.onmessage = (event) => {
                // console.log(event)
                this.WebsocketMessageReceived(event.data)
                this.$base_notify.success(event.data)
            }
            this.$socket.onerror = (event) => {
                console.log('error occurs', event)
            }
            this.$socket.onclose = () => {
                console.log('Connection is closed...')
                this.SocketDisconnected()
                if (this.token.length > 5) {
                    setTimeout(() => {
                        this.createSocketConnection()
                    }, 20000)
                }
            }
        },
    },
}
</script>
<style>
[lang='ar'] p,
[lang='ar'] h3,
[lang='ar'] h2,
[lang='ar'] h6,
[lang='ar'] h5,
[lang='ar'] h4,
[lang='ar'] span,
[lang='ar'] a,
[lang='ar'] body,
[lang='ar'] .apexcharts-datalabels-group text,
[lang='ar'] th,
[lang='ar'] td,
[lang='ar'] div,
[lang='ar'] label,
[lang='ar'] button,
[lang='ar'] li,
[lang='ar'] input,
[lang='ar'] textarea,
[lang='ar'] select option {
    font-family: 'Almarai', sans-serif !important;
}
[lang='ar'] .table-wrapper table tr td .btn img {
    margin-left: 8px;
    margin-right: 8px;
}
[lang='ar'] .activity__wrapper .tabs .nav-tabs .nav-item:last-child {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}
[lang='ar'] .activity__wrapper .tabs .nav-tabs .nav-item:first-child {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
}
[lang='ar'] .vs__clear {
    margin-right: 8px;
    margin-left: 8px;
}
.direction-ltr {
    direction: ltr !important;
}
.vs__selected {
    font-family: "Roboto", sans-serif !important;
    color: #809FB8 !important;
    font-size: 1rem !important;
}
</style>
