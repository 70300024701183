<template>
    <div>
        <div class="form-group">
            <validation-provider
                :name="name"
                v-slot="{ errors, passed }"
                :rules="rules"
            >
                <v-select
                    v-model="selectedValue"
                    :options="options_arr"
                    :searchable="searchable"
                    @input="setSelected"
                    :filterable="filterable"
                    @search="fetchOptions"
                    :class="[
                        errors.length && !bordered
                            ? ``
                            : (errors.length && bordered) || customErrors
                            ? `error-border`
                            : className,
                        passed && !readonly && !bordered
                            ? ``
                            : passed && bordered && !customErrors
                            ? `success-border`
                            : className,
                        readonly ? `readonly` : className,
                    ]"
                    :readonly="readonly"
                    :disabled="readonly"
                    :placeholder="placeholder"
                    :label="label"
                >
                    <span v-if="noOptionsText.length" slot="no-options">
                        {{ noOptionsText }}
                    </span>
                    <template #option="option">
                        <slot name="option" :option="option"></slot>
                    </template>
                    <template #selected-option="option">
                        <slot
                            name="selected-option"
                            :selected-option="option"
                        ></slot>
                    </template>
                </v-select>
                <span
                    class="invalid-feedback"
                    :class="absolute ? 'position-absolute' : ''"
                    v-if="errors.length"
                    >{{ errors[0] }}</span
                >
            </validation-provider>
        </div>
    </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'

import concat from 'lodash/concat'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
    },
    props: {
        options: Array,
        searchable: Boolean,
        filterable: Boolean,
        initialValue: String,
        fetchOptions: {
            required: false,
            default: false,
        },
        absolute: {
            default: false,
            type: Boolean,
        },
        value: [Number, String, Object],
        options_id_key: String,
        options_value_key: String,
        defaultOption: [Object, String, Number],
        className: String,
        placeholder: [String, Number],
        readonly: {
            default: false,
            type: Boolean,
        },
        bordered: Boolean,
        customErrors: Boolean,
        rules: String,
        name: String,
        noOptionsText: {
            type: String,
            default: '',
        },
        label: {
            default: 'label',
            type: String,
        },
    },
    data: () => {
        return {
            selectedValue: null,
            options_arr: [],
        }
    },
    methods: {
        setSelected(val) {
            this.$emit('select', val)
        },
        setDefaultOption() {
            if (this.defaultOption) {
                this.options_arr = concat([this.defaultOption], this.options)
                return
            }
            this.options_arr = this.options
        },
        setOptions() {
            if (this.options.length && this.options_value_key) {
                this.options.map((option) => {
                    this.options_arr.push(option[`${this.options_value_key}`])
                })
            } else {
                this.options_arr = this.options
            }
            if (this.options_arr.length) {
                this.options_arr = [...new Set(this.options_arr)]
            }
        },
    },
    created() {
        this.setDefaultOption()
        this.setOptions()
        if (this.value) {
            this.selectedValue = this.value
        }
    },
    watch: {
        options_arr: {
            handler: function (newVal) {
                if (newVal.length === 0) this.selectedValue = ''
            },
        },
        value() {
            if (this.value) {
                this.selectedValue = this.value
            }
        },
        options() {
            this.setDefaultOption()
            this.setOptions()
        },
    },
}
</script>

<style lang="scss">
.formStyle {
    .vs__dropdown-toggle {
        position: relative;
        font-family: 'Roboto', sans-serif;
        height: 3.125rem;
        font-size: 0.782rem;
        color: #809fb8;
        background-color: transparent;
        padding: 0.625rem 0.3125rem 0.625rem;
        outline: none;
        border: solid 2px #d9e1e7;
        border-radius: 16px;
        transition: all 0.2s;
        opacity: 1;
        -webkit-appearance: none !important;
    }
}
.error {
    color: red !important;
}
.error-border-bottom,
.error-border-bottom:hover,
.error-border-bottom:focus {
    border-bottom: 1px solid red !important;
}
.error-border .vs__dropdown-toggle {
    border: solid 2px red !important;
}
.success-border .vs__dropdown-toggle {
    border: solid 2px green !important;
}
.success-border-bottom,
.success-border-bottom:hover,
.success-border-bottom:focus {
    border-bottom: 1px solid green !important;
}
.success {
    color: green !important;
}
.invalid-feedback {
    display: block;
}
.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
    background-color: transparent !important;
}
</style>
