import { ApiService } from '@/common/api_services'
export const UserService = {
    query(params) {
        return ApiService.query('users/', { params: params })
    },
    getProfileCompanies(params) {
        return ApiService.query('profile-companies/', {params:params})
    },
    getVendors(params) {
        return ApiService.query('vendors-create/', { params: params })
    },
    vendorCustomersList(pk, params) {
        return ApiService.query(`vendor-customers/${pk}/`, { params: params })
    },
    get(pk) {
        return ApiService.get('users/', pk)
    },
    create(params) {
        if (params.isVendor) {
            return ApiService.post('vendors-create/', params)
        } else {
            return ApiService.post('users/', params)
        }
    },
    put(params) {
        return ApiService.put('users/', params)
    },
    destroy(pk) {
        return ApiService.delete(`users/${pk}/`)
    },
    update(pk, params) {
        return ApiService.update(`users`, pk, params)
    },
    getUsersPermissions(params) {
        return ApiService.query('user-permissions/', {
            params: params,
        })
    },
    changePassword(params) {
        return ApiService.post('change-password/', params)
    },
    generateOtp(params) {
        return ApiService.post('generate-otp/', params)
    },
    verifyOtp(params) {
        return ApiService.post('verify-otp/', params)
    },
    resetPassword(params) {
        return ApiService.post('reset-password/', params)
    },
    editAuthUser(params) {
        return ApiService.put('details/', params)
    },
    search(params) {
        return ApiService.query('search/', {
            params: params,
        })
    },
}
