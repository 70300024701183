import ApiServiceHelper from '.'

import { AUTH_LOGOUT } from '@/modules/auth/store/actions'
import { USERS, USER } from '@/modules/users/store/actions'
export const UsersHelper = {
    getProfileCompanies(params) {
        return ApiServiceHelper.execAction(USERS.COMPANIES_LIST, params)
    },
    getAuthUser(params) {
        return ApiServiceHelper.execAction(USERS.GET_AUTH_USER, params)
    },
    list(params) {
        return ApiServiceHelper.execAction(USERS.LIST, params)
    },
    vendorCustomersList(params) {
        return ApiServiceHelper.execAction(USERS.VENDOR_CUSTOMERS_LIST, params)
    },
    get(params) {
        return ApiServiceHelper.execAction(USERS.GET_USER, params)
    },
    create(params) {
        return ApiServiceHelper.execAction(USERS.CREATE, params)
    },
    update(params) {
        return ApiServiceHelper.execAction(USERS.UPDATE_USER, params)
    },
    generateOtp(params) {
        return ApiServiceHelper.execAction(USER.GENERATE_OTP, params)
    },
    verifyOtp(params) {
        return ApiServiceHelper.execAction(USER.VERIFY_OTP, params)
    },
    resetPassword(params) {
        return ApiServiceHelper.execAction(USER.RESET_PASSWORD, params)
    },
    editAuthUser(params) {
        return ApiServiceHelper.execAction(USERS.UPDATE_AUTH_USER, params)
    },
    showRow(item) {
        return ApiServiceHelper.execAction(USERS.SHOW_ROW, item)
    },
    search(params) {
        return ApiServiceHelper.execAction(USERS.SEARCH, params)
    },
    userLogout(params) {
        return ApiServiceHelper.execAction(AUTH_LOGOUT, params)
    },
}
